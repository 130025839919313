import * as React from 'react'

import { Layout, SEO } from 'components'

const ContactPage = () => {
  const [data, setData] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    message: ''
  })
  const [result, setResult] = React.useState({
    show: false,
    message: '',
    color: ''
  })

  const handleFormSubmit = async (e: any) => {
    e.preventDefault()
    const formData = new FormData()

    for (const key in data) {
      formData.append(key, data[key])
    }

    try {
      const response = await fetch('/api/submit', {
        method: 'POST',
        body: formData
      })
      const { message } = await response.json()
      if (response.status === 200) {
        showResult(message, 'bg-green-600')
        resetData()
      } else {
        showResult(message, 'bg-red-600')
      }
    } catch (error) {
      showResult(error, 'bg-red-600')
      throw new Error(error)
    }
  }

  const handleDataChange = (e: any) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value
    })
  }

  const resetData = () => {
    setData({
      first_name: '',
      last_name: '',
      email: '',
      message: ''
    })
  }

  const showResult = (message: string, color: string) => {
    setResult({ show: true, message, color })
    setTimeout(() => {
      setResult({ show: false, message: '', color: '' })
    }, 5000)
  }

  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className="max-w-2xl mx-auto mt-8 my-16">
        <h2 className="text-2xl md:text-4xl font-medium mb-4 text-center">
          We'd Love to Hear From You!
        </h2>
        <p className="mb-8 font-light text-gray-600 dark:text-white text-center">
          If you have any questions, comments, or concerns, please fill out the
          form below and we will get back to you as soon as possible.
        </p>
        {result.show && (
          <div
            className={`${result.color} text-white px-4 py-2 rounded-md font-light mb-8 text-lg`}>
            {result.message}
          </div>
        )}
        <form onSubmit={handleFormSubmit}>
          <div className="flex flex-wrap -mx-3 gap-y-6 mb-6">
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2"
                htmlFor="grid-first-name">
                First Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Jane"
                required
                name="first_name"
                onChange={handleDataChange}
                value={data.first_name}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2"
                htmlFor="grid-last-name">
                Last Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="grid-last-name"
                type="text"
                placeholder="Doe"
                required
                name="last_name"
                onChange={handleDataChange}
                value={data.last_name}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2"
                htmlFor="grid-email">
                Email Address
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="grid-email"
                type="email"
                placeholder="example@company.com"
                required
                name="email"
                onChange={handleDataChange}
                value={data.email}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2"
                htmlFor="grid-comment">
                Message
              </label>
              <textarea
                className="resize-y appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="grid-comment"
                placeholder="How can we help you?"
                required
                name="message"
                onChange={handleDataChange}
                value={data.message}
              />
            </div>
          </div>
          <button
            type="submit"
            className="block w-full uppercase tracking-wide bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded">
            Submit
          </button>
        </form>
      </div>
    </Layout>
  )
}

export default ContactPage
